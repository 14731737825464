import React from 'react'
import { ThemeProvider } from 'styled-components'
import { THEME } from './src/theme/theme'
import GlobalStyles from './src/components/GlobalStyles/GlobalStyles'

export const wrapRootElement = ({ element }) => {
  console.log(THEME)
  return (
    <ThemeProvider theme={THEME}>
      <GlobalStyles />
      {element}
    </ThemeProvider>
  )
}
