import { createGlobalStyle } from 'styled-components'

import { THEME } from '../../theme/theme'

const GlobalStyles = createGlobalStyle`
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* Global styles */
 *,
 *:before,
 *:after {
	 box-sizing:border-box;
	 line-height: 1.6;
	 -webkit-font-smoothing: antialiased;
	 -moz-osx-font-smoothing: auto;
 }
 
 #__next{
	 /* Create stacking context without z-index.
	 This ensures that all portal content (modals and tooltips) will float above the next app */
 }
 
 body {
	 color: var(--color-text);
	 background: var(--color-background);
	 height: 200vh
 }
 
 a:focus {
	 outline: 5px auto var(--color-tertiary);
	 outline-offset: 4px;
 }
 
 body, input, button, select, option {
	 font-family: var(--font-family);
	 font-weight: var(--font-weight-regular);
 }
 
 h1, h2, h3, h4, h5, h6, strong {
	 font-weight: var(--font-weight-bold)
	 /* font-weight: 900 */
 }
 
 h1, h2, h3, h4, h5, h6, strong, p {
	 text-rendering: optimizeLegibility;
 }
 p {
	 margin-bottom: 1.5em;
	 font-size: 1.125rem;
	 line-height: 1.6;
 }
 em {
	 font-style: italic;
 }
 strong {
	 font-weight: var(--font-weight-medium);
 }
 /* Scrollbar and selection styles */
 ::selection {
	 background-color: var(--color-primary);
	 color: white;
 }
 @media(orientation: landscape) {
	 ::-webkit-scrollbar {
		 width: 12px;
		 height: 11px;
		 background-color: transparent;
	 }
	 ::-webkit-scrollbar-track {
		 border-radius: 3px;
		 background-color: transparent;
	 }
	 ::-webkit-scrollbar-thumb {
		 border-radius: 10px;
		 background-color: var(--color-tertiary);
		 border: 2px solid var(--color-background);
	 }
 }

 /* Third party overrides fex video-js */
 
 /* Css Variables */
 :root {
	 --font-weight-bold: ${THEME.WEIGHTS.bold};
	 --font-weight-medium: ${THEME.WEIGHTS.medium};
	 --font-weight-regular: ${THEME.WEIGHTS.regular};

	 --font-family: "Geomanist";

	 --color-text: ${THEME.COLORS.text};
	 --color-background: ${THEME.COLORS.background};
	 --color-blurred-background: ${THEME.COLORS.blurredBackground};
	 --color-primary: ${THEME.COLORS.primary};
	 --color-secondary: ${THEME.COLORS.secondary};
	 --color-tertiary: ${THEME.COLORS.tertiary};
	 --color-decorative: ${THEME.COLORS.decorative};
	 --color-muted: ${THEME.COLORS.muted};
	 --color-info: ${THEME.COLORS.info};
	 --color-alert: ${THEME.COLORS.alert};
	 --color-alert-background: ${THEME.COLORS.alertBackground};
	 --color-error: ${THEME.COLORS.error};
	 --color-error-background: ${THEME.COLORS.errorBackground};
	 --color-success: ${THEME.COLORS.success};
	 --color-success-background: ${THEME.COLORS.successBackground};
	 --color-gray-100: ${THEME.COLORS.gray[100]};
	 --color-gray-200: ${THEME.COLORS.gray[200]};
	 --color-gray-300: ${THEME.COLORS.gray[300]};
	 --color-gray-400: ${THEME.COLORS.gray[400]};
	 --color-gray-500: ${THEME.COLORS.gray[500]};
	 --color-gray-600: ${THEME.COLORS.gray[600]};
	 --color-gray-700: ${THEME.COLORS.gray[700]};
	 --color-gray-800: ${THEME.COLORS.gray[800]};
	 --color-gray-900: ${THEME.COLORS.gray[900]};
	 --color-gray-1000: ${THEME.COLORS.gray[1000]};
 }
 
 `

export default GlobalStyles
