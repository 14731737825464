export const COLORS = {
  text: 'hsl(208.7deg,29.1%,15.5%)',
  background: 'hsl(0deg, 0%, 100%)',
  blurredBackground: 'hsla(0deg, 0%, 100%, 0.85)',
  primary: 'hsl(207.5deg,100%,50%)',
  secondary: 'hsl(33.5deg,93.1%,54.3%)',
  tertiary: 'hsl(207.7deg,100%,32.7%)',
  decorative: 'hsl(219.9deg,75.3%,65.1%)',
  info: 'hsl(207.6deg,69.6%,51%)',
  muted: 'hsl(219.1deg,56.1%,92%)',
  success: 'hsl(130deg,100%,40%)',
  successBackground: 'hsla(130deg,100%,40%,0.1)',
  alert: 'hsl(47.1deg,100%,50%)',
  alertBackground: 'hsla(47.1deg,100%,50%,0.25)',
  error: 'hsl(0deg,95.3%,50%)',
  errorBackground: 'hsla(0deg,95.3%,50%,0.1)',
  gray: {
    100: 'hsl(225deg, 25%, 95%)',
    200: 'hsl(225deg, 16%, 90%)',
    300: 'hsl(225deg, 8%, 80%)',
    400: 'hsl(225deg, 8%, 70%)',
    500: 'hsl(225deg, 7%, 60%)',
    600: 'hsl(225deg, 15%, 50%)',
    700: 'hsl(225deg, 12%, 40%)',
    800: 'hsl(225deg, 30%, 30%)',
    900: 'hsl(225deg, 25%, 20%)',
    1000: 'hsl(225deg, 15%, 15%)',
  },
}

export const WEIGHTS = {
  bold: 700,
  medium: 500,
  regular: 400,
}

export const BREAKPOINTS = {
  tabletsAndBigger: 550,
  laptop: 1100,
  desktop: 1500,
}

export const QUERIES = {
  tabletsAndBigger: `(min-width: ${BREAKPOINTS.tabletsAndBigger / 16}rem)`,
  laptopsAndBigger: `(min-width: ${BREAKPOINTS.laptop / 16}rem)`,
  desktopsAndBigger: `(min-width: ${BREAKPOINTS.desktop / 16}rem)`,
}

export const THEME = {
  COLORS,
  BREAKPOINTS,
  QUERIES,
  WEIGHTS,
}
